/* eslint-disable react-hooks/exhaustive-deps */
import { ToggleButton, Alert, CardActions } from '@mui/material'
import Typography from '@mui/material/Typography'
import TodayIcon from '@mui/icons-material/Today'
import { makeStyles, createStyles } from '@mui/styles'
import { useState, useEffect } from 'react'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { flipToggle } from './WorkOrderListStore/actionCreator'
import { postData } from '../../service/HttpService'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  ADD_SCHEDULE,
  REMOVE_SCHEDULE,
  SCHEDULED,
  SCHEDULES,
  SCHEDULE_OR_ASSIGNMENT,
  globalRoutes,
} from '../../globalConstants'
import { useAuth } from '@praxis/component-auth'
import { useSelector } from 'react-redux'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'

const useStyles = makeStyles((theme) =>
  createStyles({
    actionIcon: {
      marginRight: theme.spacing(1),
    },
    toggleButton: {
      marginLeft: 'auto',
      padding: theme.spacing(0.75),
    },
    alertMessage: {
      margin: theme.spacing(0, 1),
    },
  }),
)

const ToggleScheduleButton = ({
  workorder,
  setShouldRender,
  assignments,
  flipToggle,
  woLength,
  callback,
  from,
  pageSavedScroll,
  index,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { storeId } = useSelector((state) => state.storeInfoReducer)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const isFromSchedules = from === SCHEDULES
  const [isScheduled, setScheduled] = useState(isFromSchedules ? true : false)
  const [assignmentId, setAssignmentId] = useState('')
  const [buttonText, setButtonText] = useState(
    isFromSchedules ? REMOVE_SCHEDULE : ADD_SCHEDULE,
  )
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const woNum = workorder.wonum
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))
  const loadingMessage = `Updating ${SCHEDULE_OR_ASSIGNMENT(isDCUser)}...`
  const auth = useAuth()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const [hasMultipleAssignments, setHasMultipleAssignments] = useState(false)

  useEffect(() => {
    if (assignments?.length && woLength && !isFromSchedules) {
      const checkForMultipleAssignments = (assignmentDates) => {
        return new Set(assignmentDates).size !== assignmentDates.length
      }
      const thisAssignment = assignments.filter((a) => a.wonum === woNum)
      const isAssigned = thisAssignment.length > 0
      const assignmentDates = thisAssignment.map(function (item) {
        return new Date(item.scheduledate).toDateString()
      })
      setHasMultipleAssignments(checkForMultipleAssignments(assignmentDates))
      setScheduled(isAssigned)
      setButtonText(
        isFromSchedules
          ? REMOVE_SCHEDULE
          : isAssigned
            ? SCHEDULED
            : ADD_SCHEDULE,
      )
      isAssigned && setAssignmentId(thisAssignment[0].assignmentid)
    }
  }, [assignments, woLength, workorder])

  const handleAddScheduleDate = async (date) => {
    setDatePickerOpen(false)
    const assignmentObject = !isScheduled
      ? {
          woNum: woNum,
          woId: workorder.woid,
          userId: userId,
          hours: 1,
          scheduledStartDate: moment(date),
          craft: workorder.woassigncraft,
          laborCode: userId,
          siteId: storeId,
        }
      : {
          woNum: woNum,
          woId: workorder.woid,
          userId: userId,
          assignmentId: isFromSchedules ? workorder.assignmentid : assignmentId,
          siteId: storeId,
        }
    try {
      setError(false)
      setShouldRender(true, loadingMessage)
      let url = `/maximo/assignment/new?woid=${workorder.woid}`
      let scheduled = true
      let buttonText = SCHEDULED
      if (isScheduled) {
        url = '/maximo/assignment/unassign'
        scheduled = false
        buttonText = ADD_SCHEDULE
      }
      await postData(url, JSON.stringify(assignmentObject))
      setScheduled(scheduled)
      setButtonText(isFromSchedules ? REMOVE_SCHEDULE : buttonText)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
      setShouldRender(false)
      !callback && flipToggle()
      callback && callback()
    }
  }

  const toggleSchedule = (event) => {
    setAnchorEl(event.currentTarget)
    !isFromSchedules && sessionStorage.setItem(pageSavedScroll, index)

    if (!isScheduled && !isFromSchedules) {
      setDatePickerOpen(true)
    } else {
      !isFromSchedules
        ? navigate(
            `${globalRoutes.workOrderDetails}/schedules#?wonum=${workorder.wonum}&showBack=true&workorderId=${workorder.woid}`,
          )
        : handleAddScheduleDate()
    }
  }

  return (
    <>
      {error && !loading && (
        <Alert severity="error">
          Unable to add / remove {SCHEDULE_OR_ASSIGNMENT(isDCUser)}. Refresh the
          page later to try again.
        </Alert>
      )}
      {hasMultipleAssignments && !isFromSchedules && (
        <Alert severity="info">
          Multiple Assignments. Remove one from the{' '}
          {SCHEDULE_OR_ASSIGNMENT(isDCUser)} page.
        </Alert>
      )}
      {(!hasMultipleAssignments || isFromSchedules) && !error && (
        <CardActions>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ToggleButton
              className={classes.toggleButton}
              selected={isFromSchedules ? true : isScheduled}
              onChange={(e) => toggleSchedule(e)}
              value={isScheduled}
              color={'primary'}
            >
              <TodayIcon className={classes.actionIcon} />
              <Typography>{buttonText}</Typography>
            </ToggleButton>
            <DesktopDatePicker
              renderInput={() => null}
              open={datePickerOpen}
              onClose={() => setDatePickerOpen(false)}
              onChange={(date) => handleAddScheduleDate(date)}
              PopperProps={{
                placement: 'bottom-end',
                anchorEl: anchorEl,
                'data-testid': 'date-picker',
              }}
              minDate={moment().subtract(0, 'days')}
              maxDate={moment().add(7, 'days')}
            />
          </LocalizationProvider>
        </CardActions>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    assignments: state.workOrderListReducer.assignments,
  }
}
const mapDispatchToProps = {
  setShouldRender,
  flipToggle,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToggleScheduleButton)
