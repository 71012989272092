/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import { fetchData, fetchDataParallel } from '../../service/HttpService'
import { makeStyles, createStyles } from '@mui/styles'
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import MatAppBar from '../MatAppBar/MatAppBar'
import {
  api,
  WORK_ORDER,
  WORKORDER_ERROR,
  SHORT_DESCRIPTION_CHARACTER_LIMIT,
  globalRoutes,
  FOLLOW_UP_WO_URL_PARAM,
  STORE_REQUEST_ID_FIELD,
  FOLLOW_UP_SR_URL_PARAM,
  WORK_ORDER_NUMBER_FIELD,
} from '../../globalConstants'
import { useAuth } from '@praxis/component-auth'
import { useNavigate } from 'react-router-dom'
import { resetWorkOrder } from '../CreateWorkOrder/actionCreator'
import { postData, putData } from '../../service/HttpService'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { Alert } from '@mui/material'
import { setSelectedArea } from '../AreaOfStore/actionCreator'
import { setSelectedAsset } from '../AssetsSelect/AssetsSelectStore/actionCreator'
import { getURLSearchParams } from '../../windowManager'
import DynamicTextField from '../common/DynamicTextField'
import { useSelector } from 'react-redux'
import PlannedTime from '../PlannedTime/PlannedTime'
import { isHoursInvalid, isMinutesInvalid } from '../../utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: theme.spacing(3, 0),
      wordBreak: 'break-word',
    },
    cancelBtn: {
      whiteSpace: 'nowrap',
    },
  }),
)

const commonProps = {
  xs: 6,
  item: true,
}

const CreateWorkOrder = ({
  createWorkOrderData,
  storeId,
  area,
  asset,
  resetWorkOrder,
  setShouldRender,
  setSelectedArea,
  setSelectedAsset,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [shortDescription, setShortDescription] = useState(null)
  const [longDescription, setLongDescription] = useState('')
  const [selectedJobPlan, setSelectedJobPlan] = useState('')
  const [defaultCraft, setDefaultCraft] = useState('')
  const [jobPlanList, setJobPlanList] = useState([])
  const [vendor, setVendor] = useState('')
  const [vendorList, setVendorList] = useState([])
  const [fullVendorList, setFullVendorList] = useState([])
  const [reasons, setReasons] = useState([])
  const [reason, setReason] = useState('')
  const [isExternal, setIsExternal] = useState(false)
  const [isRestricted, setIsRestricted] = useState(false)
  const [reasonRequired, setReasonRequired] = useState(false)
  const [crafts, setCrafts] = useState({})
  const [hours, setHours] = useState(1)
  const [minutes, setMinutes] = useState(0)
  const [priorityList, setPriorityList] = useState([])
  const [priority, setPriority] = useState('')
  const [DCCraft, setDCCraft] = useState('MT')
  const [craftList, setCraftList] = useState([])
  const { areaOrLocation, isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const workOrderData = createWorkOrderData
  const navigate = useNavigate()
  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId
  const workOrderDataProblem = createWorkOrderData?.problem
  const problemLocation = createWorkOrderData.problemLocation
  const assetDescription = asset?.description
  const assetFailureCode = asset.failureCode
  const assetMapIndex = asset?.mapIndex
  const assetName = asset?.assetName
  const assetNumber = asset?.assetNum
  const assetTag = asset?.assetTag
  const failureCode = assetFailureCode
    ? assetFailureCode
    : createWorkOrderData.failureCode
  const assetLocation = asset?.assetLocation
  const searchParams = getURLSearchParams()
  const isFollowUp =
    searchParams.get(FOLLOW_UP_WO_URL_PARAM) ||
    searchParams.get(FOLLOW_UP_SR_URL_PARAM)
  const isFollowUpSr = searchParams.get(FOLLOW_UP_SR_URL_PARAM)
  const valueToSendForFollowUpWo =
    searchParams.get(WORK_ORDER_NUMBER_FIELD) ||
    searchParams.get(STORE_REQUEST_ID_FIELD)
  const myRef = useRef(null)
  const shortDescriptionCount = shortDescription?.length
  const isShortDescriptionBlank = !/\S/.test(shortDescription)
  const ticketUId = searchParams.get('ticket-uid')

  const conditionalProps = {
    item: true,
    xs: 12,
    sm: isDCUser ? 6 : 12,
  }

  useEffect(() => {
    async function getData() {
      try {
        setShouldRender(true, 'Loading...')

        const apiCall = problemLocation
          ? [
              `/mam/locationjobplans/siteId/${storeId}?location=${problemLocation}&problemcode=${workOrderDataProblem}`,
            ]
          : [
              `/mam/jobplan/default/siteId/${storeId}?assetnum=${assetNumber}&problemcode=${workOrderDataProblem}&mapindex=${assetMapIndex}&userid=${userId}`,
              `/mam/jobplan/nondefault/siteId/${storeId}?assetnum=${assetNumber}&problemcode=${workOrderDataProblem}&mapindex=${assetMapIndex}&userid=${userId}`,
              '/mam/external-redirect/reason-codes',
            ]
        isDCUser && apiCall.push(`/mam/priority/dc`)
        isDCUser && apiCall.push(`/mam/crafts`)

        const result = await fetchDataParallel(apiCall)
        const firstResult = result[0]
        const secondResult = !problemLocation ? result[1] : {}
        const fourthResult = result[4]
        const redirectReason = !problemLocation ? result[2]?.redirectreason : []
        const assetJobPlan = !problemLocation ? firstResult?.jobplans?.[0] : {}
        const assetJobPlanList = secondResult?.jobplans
        const locationJobPlanList = firstResult?.locationjobplanList
        const craftData = fourthResult?.craftdata
        if (isDCUser) {
          const resultIndex = problemLocation ? 1 : 3
          setPriorityList(result[resultIndex].PriorityData)
          setCraftList(craftData)
        }
        const defaultJobPlan = problemLocation
          ? locationJobPlanList?.[0]?.jobplan
          : assetJobPlan?.jobplan

        const jobPlans = problemLocation
          ? locationJobPlanList.map(({ jobplan }) => jobplan)
          : assetJobPlanList.map(({ jobplan }) => jobplan)
        defaultJobPlan && jobPlans.push(defaultJobPlan) // only add if defined
        jobPlans && setJobPlanList([...new Set(jobPlans)])

        setSelectedJobPlan(defaultJobPlan ?? jobPlans?.[0])

        problemLocation
          ? findCrafts(locationJobPlanList)
          : findCrafts(firstResult?.jobplans)

        !problemLocation && findCrafts(assetJobPlanList)

        setDefaultCraft(crafts[defaultJobPlan ?? jobPlans?.[0]])

        if (!problemLocation) {
          // Need to keep vendor order to preserve ranking, received in rank order from Sunflower
          const vendorList = firstResult?.jobplans?.concat(assetJobPlanList)
          const nameCompanyList = vendorList
            .map(({ name, company, jobplan }) => ({ name, company, jobplan }))
            .filter(
              (jobPlan) =>
                jobPlan.name && jobPlan.company && jobPlan.jobplan != null,
            )
          let uniqueVendors = []
          nameCompanyList.forEach((vendor) => {
            let duplicateFlag = false
            for (let uniqueVendor of uniqueVendors) {
              if (
                uniqueVendor.name === vendor.name &&
                uniqueVendor.company === vendor.company &&
                uniqueVendor.jobplan === vendor.jobplan
              ) {
                duplicateFlag = true
              }
            }
            !duplicateFlag && uniqueVendors.push(vendor)
          })
          setFullVendorList(uniqueVendors)
          setVendorList(
            filterVendors(defaultJobPlan ?? jobPlans?.[0], uniqueVendors),
          )

          redirectReason && setReasons(redirectReason)

          if (!isDCUser) {
            ;(defaultJobPlan ?? jobPlans?.[0] ?? '').match(/-INT/) &&
              setReasonRequired(true)
          } else {
            !(defaultJobPlan ?? jobPlans?.[0] ?? '')?.match(/-EXT/) &&
              setReasonRequired(true)
          }

          await isInternalJobRestricted(defaultJobPlan ?? jobPlans?.[0])
          isJobExternal(defaultJobPlan ?? jobPlans?.[0])
        }
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
        setShouldRender(false)
      }
    }
    getData()
  }, [])

  const findCrafts = (jobplans) => {
    const craftDictionary = crafts
    jobplans.forEach((jobPlan) => {
      if (jobPlan.craft) {
        craftDictionary[jobPlan.jobplan] = jobPlan.craft
      }
    })
    setCrafts(craftDictionary)
  }

  const clearWorkOrderData = () => {
    resetWorkOrder()
    setSelectedArea({})
    setSelectedAsset({})
  }

  const saveWorkOrder = async () => {
    if (userId) {
      const workOrderObject = {
        'org-class':
          isFollowUpSr && valueToSendForFollowUpWo ? 'SR' : 'WORKORDER', // change class for Follow Up for Store Request
        area: area?.location,
        asset: assetName,
        'failure-code': failureCode,
        'asset-num': assetNumber ?? '',
        'asset-desc': assetDescription,
        problem: workOrderDataProblem,
        siteid: storeId,
        'short-description': shortDescription,
        'long-description': longDescription,
        'reported-by': userId,
        'job-plan':
          !selectedJobPlan || selectedJobPlan === 'NONE' ? '' : selectedJobPlan,
        'redirect-reason': reason,
        'assigned-to': isExternal
          ? vendorList.find((vendorObj) => vendorObj.name === vendor)?.company
          : defaultCraft,
        'external-flag': isExternal,
        'redirect-flag': isRestricted ? 1 : 0,
        location: assetLocation ?? problemLocation,
        'map-index': assetMapIndex,
        priority: isDCUser ? priority : '',
        // required property for Follow Up WO
        ...(isFollowUp
          ? {
              'my-support-ticket-id': valueToSendForFollowUpWo,
            }
          : {}),
        ...(isDCUser
          ? {
              'assignment-craft': DCCraft,
              'assignment-hours': hours,
              'assignment-minutes': minutes,
            }
          : {}),
      }

      try {
        setShouldRender(true, `Creating ${WORK_ORDER}...`)
        const response = await postData(
          `/maximo/workorder`,
          JSON.stringify(workOrderObject),
        )

        isFollowUpSr &&
          (await putData(
            api.statusUpdate,
            JSON.stringify({
              'ticket-id': valueToSendForFollowUpWo,
              'ticket-uid': ticketUId,
              status: 'CLOSED',
            }),
          ))

        clearWorkOrderData()
        navigate(
          `${globalRoutes.workOrderDetails}#?wonum=${response.Workorder.wonum}`,
        )
      } catch (error) {
        setError(true)
        window.scrollTo(0, 0)
      } finally {
        setLoading(false)
        setShouldRender(false)
      }
    } else {
      setError(true)
      window.scrollTo(0, 0)
    }
  }

  const filterVendors = (jobPlan, vendorList) => {
    const filteredVendors = vendorList.filter(
      (vendor) => vendor.jobplan === jobPlan,
    )
    setVendor(filteredVendors[0]?.name || '')
    return filteredVendors
  }

  const handleJobPlan = (e) => {
    const value = e.target.value
    setVendorList(filterVendors(value, fullVendorList))
    setSelectedJobPlan(value)
    setDefaultCraft(crafts[value])
    isJobExternal(value)
    setReason('')
  }

  const cancelWorkOrder = () => {
    clearWorkOrderData()
    navigate('/home')
  }

  const isInternalJobRestricted = async (selectedJob) => {
    try {
      const isRestrictedResponse = await fetchData(
        `/mam/external-redirect-restrict?problemcode=${workOrderDataProblem}&jobplan=${selectedJob}&mapindex=${assetMapIndex}`,
      )
      setIsRestricted(
        isRestrictedResponse.redirect?.redirectFlag === 1 ? true : false,
      )
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
      setShouldRender(false)
    }
  }

  const isJobExternal = (job) => {
    // Need to add dash to differentiate between external wall internal solution vs external vendor
    if (isDCUser) {
      job?.match(/-EXT/) ? setIsExternal(true) : setIsExternal(false)
    } else {
      job.match(/-EXT/) ? setIsExternal(true) : setIsExternal(false)
    }
  }

  const shortDescriptionHelperText = (
    <>
      <Grid container spacing={2}>
        <Grid {...commonProps}>
          {`${shortDescriptionCount}/${SHORT_DESCRIPTION_CHARACTER_LIMIT}`}
          <div>
            {`${
              isShortDescriptionBlank
                ? 'Short Description cannot be blank.'
                : ''
            }`}
          </div>
          <div>
            {`${
              shortDescriptionCount > SHORT_DESCRIPTION_CHARACTER_LIMIT
                ? 'Short Description must not exceed 100 characters.'
                : ''
            }
         `}
          </div>
        </Grid>
      </Grid>
    </>
  )

  return (
    <>
      <MatAppBar
        arrowBack
        text={`Create ${WORK_ORDER}`}
        renderFollowUpInfoIfExists
      />
      {error && (
        <Alert severity="error" data-testid="workorder-error">
          {WORKORDER_ERROR}
        </Alert>
      )}
      <Container className={classes.container}>
        <Grid container spacing={4}>
          <Grid {...commonProps}>
            <Typography>Asset:</Typography>
          </Grid>
          <Grid {...commonProps}>
            <Typography>{assetName}</Typography>
          </Grid>
          <Grid {...commonProps}>
            <Typography>Asset Description: </Typography>
          </Grid>
          <Grid {...commonProps}>
            <Typography>{assetDescription}</Typography>
          </Grid>
          {isDCUser && (
            <>
              <Grid {...commonProps}>
                <Typography>Asset Tag: </Typography>
              </Grid>
              <Grid {...commonProps}>
                <Typography>{assetTag}</Typography>
              </Grid>
            </>
          )}
          <Grid {...commonProps}>
            <Typography>{`${areaOrLocation}:`}</Typography>
          </Grid>
          <Grid {...commonProps}>
            <Typography>{workOrderData.areaOfStore}</Typography>
          </Grid>
          <Grid {...commonProps}>
            <Typography>Problem:</Typography>
          </Grid>
          <Grid {...commonProps}>
            <Typography>{workOrderDataProblem}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Short Description"
              variant="outlined"
              multiline
              fullWidth
              rows={2}
              FormHelperTextProps={{
                component: 'div',
              }}
              onChange={(e) => {
                setShortDescription(e.target.value)
              }}
              id="short-description-textfield"
              data-testid="short-description-textfield"
              error={
                shortDescription !== null &&
                (shortDescriptionCount > SHORT_DESCRIPTION_CHARACTER_LIMIT ||
                  shortDescriptionCount === 0 ||
                  isShortDescriptionBlank)
              }
              helperText={
                shortDescriptionHelperText && shortDescription !== null
                  ? shortDescriptionHelperText
                  : ' '
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DynamicTextField
              myRef={myRef}
              label="Long Description"
              buttonAriaLabel="Save Long Description"
              id="long-description-textfield"
              onChange={(val) => setLongDescription(val)}
              rowsFromParent={4}
            />
          </Grid>
          <Grid {...conditionalProps}>
            <FormControl fullWidth size="small">
              <InputLabel>Job Plan</InputLabel>
              <Select
                value={
                  loading
                    ? ''
                    : isDCUser && !selectedJobPlan
                      ? 'NONE'
                      : selectedJobPlan
                }
                label="Job Plan"
                onChange={(e) => handleJobPlan(e)}
                inputProps={{ 'data-testid': 'job-plan' }}
              >
                {jobPlanList.length > 0 &&
                  !loading &&
                  jobPlanList.map((jobPlan, index) => {
                    const item = jobPlan ? jobPlan : 'NONE'
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>

          {isDCUser && !loading && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Craft</InputLabel>
                <Select
                  value={loading ? '' : DCCraft}
                  label="Craft"
                  onChange={(e) => setDCCraft(e.target.value)}
                  inputProps={{ 'data-testid': 'DCCraft' }}
                >
                  {craftList?.length > 0 &&
                    !loading &&
                    craftList.map(({ craft }, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={craft}
                          style={{ whiteSpace: 'normal' }}
                        >
                          {craft}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {isDCUser && !loading && (
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel>Priority</InputLabel>
                <Select
                  value={loading ? '' : priority}
                  label="Calc Priority"
                  onChange={(e) => setPriority(e.target.value)}
                  inputProps={{ 'data-testid': 'priority' }}
                >
                  {priorityList.length > 0 &&
                    !loading &&
                    priorityList.map(({ value, description }, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={parseInt(value)}
                          style={{ whiteSpace: 'normal' }}
                        >
                          {parseInt(value)}
                          {' - ' + description}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {!isDCUser && !loading && !isExternal && (
            <>
              <Grid {...commonProps}>
                <Typography>Assign to Craft:</Typography>
              </Grid>
              <Grid {...commonProps}>
                <Typography>
                  {defaultCraft === 'SFT' ? 'PML' : defaultCraft}
                </Typography>
              </Grid>
            </>
          )}

          {isDCUser && !loading && (
            <>
              <Grid {...commonProps}>
                <Typography>Planned Time:</Typography>
              </Grid>
              <Grid {...commonProps}>
                <PlannedTime
                  hours={hours}
                  minutes={minutes}
                  onHoursChange={setHours}
                  onMinutesChange={setMinutes}
                />
              </Grid>
            </>
          )}

          {isExternal && (
            <>
              <Grid {...commonProps}>
                <Typography>Assign to Vendor:</Typography>
              </Grid>
              <Grid {...commonProps}>
                <FormControl fullWidth size="small">
                  <InputLabel>Assign to Vendor</InputLabel>
                  <Select
                    value={loading ? '' : vendor}
                    label="Assign to Vendor"
                    onChange={(e) => setVendor(e.target.value)}
                  >
                    {vendorList.length > 0 &&
                      !loading &&
                      vendorList.map(({ name }, index) => {
                        return (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
              {!isDCUser && reasonRequired && (
                <>
                  <Grid {...commonProps}>
                    <Typography>Reason:</Typography>
                  </Grid>

                  <Grid {...commonProps}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Reason</InputLabel>
                      <Select
                        value={reason}
                        label="Reason"
                        onChange={(e) => setReason(e.target.value)}
                        inputProps={{ 'data-testid': 'redirect-reason' }}
                      >
                        {reasons.length > 0 &&
                          !loading &&
                          reasons.map(({ value }, index) => {
                            return (
                              <MenuItem key={index} value={value}>
                                {value}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item container justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                onClick={cancelWorkOrder}
                data-testid="cancel-create-workorder"
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={
                  !shortDescription ||
                  shortDescription.trim().length === 0 ||
                  shortDescriptionCount > SHORT_DESCRIPTION_CHARACTER_LIMIT ||
                  (!isDCUser &&
                    (!selectedJobPlan ||
                      selectedJobPlan.trim().length === 0)) ||
                  (isRestricted && isExternal && reason === '') ||
                  (isDCUser &&
                    ((!hours && !minutes) ||
                      (isHoursInvalid(hours) && isMinutesInvalid(minutes)) ||
                      !DCCraft ||
                      !priority))
                }
                onClick={saveWorkOrder}
              >
                {`Create ${WORK_ORDER}`}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storeId: state.storeInfoReducer.storeId,
    createWorkOrderData: state.createWorkOrderReducer.createWorkOrderData,
    asset: state.assetSelectReducer.selectedAsset,
    area: state.areaOfStoreReducer.selectedArea,
    wodetails: state.workOrderDetailsReducer.wodetails,
    userRoles: state.userInfoReducer.userRoles,
  }
}
const mapDispatchToProps = {
  resetWorkOrder,
  setShouldRender,
  setSelectedArea,
  setSelectedAsset,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkOrder)
