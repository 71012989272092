import { fetchData } from '../service/HttpService'

export const poll = async ({
  fn,
  validate,
  interval = 750,
  maxAttempts = 80,
}) => {
  let attempts = 0

  const executePoll = async (resolve, reject) => {
    const result = await fn()
    attempts++

    try {
      const isValid = await validate(result)
      if (isValid) {
        return resolve(result)
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error('Exceeded max attempts'))
      } else {
        setTimeout(executePoll, interval, resolve, reject)
      }
    } catch (error) {
      return reject(error)
    }
  }

  return new Promise(executePoll)
}

const generateDateString = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const pollSparePartsPropagation = async (
  woassetnum,
  wositeid,
  prevbal,
  requestBody,
) => {
  const fn = async () => {
    try {
      const pollResponse = await fetchData(
        `/mam/buildofmaterials/siteId/${wositeid}/assetNum/${woassetnum}`,
      )
      return pollResponse.bomdata
    } catch (error) {
      console.error('Error in polling function:', error)
      throw error
    }
  }

  const validate = async (sparePartResponse) => {
    const item = sparePartResponse.find(
      (i) =>
        i.binnum === requestBody.binNum && i.itemnum === requestBody.itemNum,
    )
    const itemDtFormatted = item.lastIssueDate.split(' ')[0]
    const thisDtCompare = generateDateString()
    // here we take the previous balance
    // and perform +/- operation based on the quantity
    // and assert the new balance matches expectation
    return (
      parseFloat(item.curbal) ===
        parseFloat(parseFloat(prevbal) + requestBody.quantity) &&
      itemDtFormatted === thisDtCompare
    )
  }
  return poll({ fn, validate })
}

export const pollCycleCountPropagation = async (userStoreroom, requestBody) => {
  const fn = async () => {
    try {
      const pollResponse = await fetchData(
        `/mam/items/storenum/${userStoreroom}?itemnum=${requestBody.itemnum}`,
      )
      return pollResponse.itemDetails
    } catch (error) {
      console.error('Error in polling function:', error)
      throw error
    }
  }

  const validate = async (response) => {
    const item = response.find(
      (i) =>
        i.binnum === requestBody.binnum && i.itemNum === requestBody.itemnum,
    )
    const itemDtFormatted = item.physcntdate.split(' ')[0]
    const thisDtCompare = generateDateString()
    return (
      parseInt(item.physcnt, 10) === requestBody.physcount &&
      itemDtFormatted === thisDtCompare
    )
  }
  return poll({ fn, validate })
}

export const pollStoreroomPropagation = async (
  userStoreroom,
  prevbal,
  requestBody,
) => {
  const fn = async () => {
    try {
      const pollResponse = await fetchData(
        `/mam/items/storenum/${userStoreroom}`,
      )
      return pollResponse.itemDetails
    } catch (error) {
      console.error('Error in polling function:', error)
      throw error
    }
  }

  const validate = async (response) => {
    const item = response.find(
      (i) =>
        i.binnum === requestBody.binNum && i.itemNum === requestBody.itemNum,
    )
    // here we take the previous balance
    // and perform +/- operation based on the quantity
    // and assert the new balance matches expectation
    const countMatch =
      parseFloat(item.avblbalance) ===
      parseFloat(parseFloat(prevbal) + requestBody.quantity)
    if (countMatch) {
      // if the count matches we make another call to verify the date is updated
      const verifyDateResponse = await fetchData(
        `/mam/itemsissued/siteid/${requestBody.siteId}/wonum/${requestBody.woNum}`,
      )
      const dateItem = verifyDateResponse.itemsissueddata.find(
        (i) =>
          i.binnum === requestBody.binNum && i.itemnum === requestBody.itemNum,
      )
      if (!dateItem) {
        // all issued parts would return an empty array
        // and dateItem would not find a match
        return true
      }
      const itemDtFormatted = dateItem.lastissuedate.split(' ')[0]
      const thisDtCompare = generateDateString()
      const curBalUpdated =
        parseFloat(dateItem.currbalance) ===
        parseFloat(parseFloat(prevbal) + requestBody.quantity)
      return curBalUpdated && itemDtFormatted === thisDtCompare
    } else {
      return false
    }
  }
  return poll({ fn, validate })
}
