import { propFromObjectArrayToFixed, filterAPWorkorders } from '../../../utils'
import {
  SET_WORKORDERS_LIST,
  SET_DUPLICATE_WORKORDERS_LIST,
  SET_ASSIGNMENTS,
  FLIP_TOGGLE,
  SET_INTERNAL_WORKORDERS_LIST,
  SET_ACTION_NEEDED_VENDOR_WORKORDERS_LIST,
  SET_ACTION_NEEDED_WORKORDERS_LIST,
  SET_EMERGENCY_MAINTENANCE_WORKORDERS_LIST,
  SET_EXTERNAL_WORKORDERS_LIST,
  SET_REDIRECTED_WORKORDERS_LIST,
  SET_VENDOR_VALIDATION_WORKORDERS_LIST,
  SET_CM_EM_WORKORDERS_LIST,
  SET_FIELD_PROJECT_WORKORDERS_LIST,
  SET_PREVENTATIVE_MAINTENANCE_WORKORDERS_LIST,
  SET_CANCELLED_EXTERNAL_WORKORDERS_LIST,
  SET_SAFETY_WORKORDERS_LIST,
  SET_AUTO_WORKORDERS_LIST,
  SET_AUTH,
} from './actionType'
import moment from 'moment'
import { OPEN_WORK_ORDERS_PROP } from '../../../globalConstants'
import { getIsDCUser } from '../../../utils/hooks/rolesAndPermissions'

export const initialState = {
  [OPEN_WORK_ORDERS_PROP]: [],
  duplicateWorkorders: [],
  assignments: [],
  toggle: null,
  internalWorkorders: [],
  externalWorkOrders: [],
  cmWorkOrders: [],
  redirectedWorkOrders: [],
  actionNeededVendorWorkOrders: [],
  fieldProjectWorkorders: [],
  preventativeMaintenanceWorkorders: [],
  cancelledExtWorkOrders: [],
  safetyWorkorders: [],
  autoWorkorders: [],
  userGroups: [],
  lastUpdated: {
    [OPEN_WORK_ORDERS_PROP]: null,
    internalWorkorders: null,
    actionNeededVendorWorkOrders: null,
    actionNeededWorkorders: null,
    emergencyMaintenanceWorkorders: null,
    externalWorkOrders: null,
    redirectedWorkOrders: null,
    vendorWorkValidationWOs: null,
    cmWorkOrders: null,
    fieldProjectWorkorders: null,
    preventativeMaintenanceWorkorders: null,
    cancelledExtWorkOrders: null,
    safetyWorkorders: null,
    autoWorkorders: null,
    userGroups: null,
  },
}

export default function workOrderListReducer(
  state = initialState,
  action = {},
) {
  const tomorrow = moment.utc().add(1, 'day').format()
  const isDCUser = getIsDCUser(state.userGroups)

  const sortListBasedOnUserGroup = (workOrders) =>
    workOrders?.sort(function (a, b) {
      return isDCUser
        ? new Date(a.reportdate) - new Date(b.reportdate)
        : new Date(a.repair_by) - new Date(b.repair_by)
    })

  switch (action.type) {
    case SET_AUTH: {
      const newState = {
        ...state,
        userGroups: action.userGroups,
      }
      return newState
    }
    case SET_WORKORDERS_LIST: {
      const workOrders = propFromObjectArrayToFixed(
        action.workorders,
        'ltreghrs',
      )

      const sortedWorkOrders = sortListBasedOnUserGroup(workOrders)

      const APFilteredWorkOrders = filterAPWorkorders(
        sortedWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        [OPEN_WORK_ORDERS_PROP]: APFilteredWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          [OPEN_WORK_ORDERS_PROP]: moment().format('LLL'),
        },
      }
      return newState
    }

    case SET_DUPLICATE_WORKORDERS_LIST: {
      const newState = {
        ...state,
        duplicateWorkorders: action.duplicateWorkorders,
      }
      return newState
    }
    case SET_INTERNAL_WORKORDERS_LIST: {
      const workOrders = propFromObjectArrayToFixed(
        action.internalWorkorders,
        'ltreghrs',
      )

      const sortedWorkOrders = sortListBasedOnUserGroup(workOrders)

      const internalWorkOrders = sortedWorkOrders?.filter((currentElement) => {
        return currentElement.external === '0'
      })

      const APFilteredInternalWorkOrders = filterAPWorkorders(
        internalWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        internalWorkorders: APFilteredInternalWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          internalWorkorders: moment().format('LLL'),
        },
      }
      return newState
    }
    case SET_ACTION_NEEDED_WORKORDERS_LIST: {
      const actionNeededWorkorders = propFromObjectArrayToFixed(
        action.actionNeededWorkorders,
        'ltreghrs',
      )
      const sortedWorkOrders = actionNeededWorkorders?.sort(function (a, b) {
        return new Date(a.repair_by) - new Date(b.repair_by)
      })

      const filteredActionWorkOrders = sortedWorkOrders?.filter((currentWO) => {
        return currentWO.repair_by < tomorrow && currentWO.external === '0'
      })

      const APFilteredActionWorkOrders = filterAPWorkorders(
        filteredActionWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        actionNeededWorkorders: APFilteredActionWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          actionNeededWorkorders: moment().format('LLL'),
        },
      }
      return newState
    }

    case SET_ACTION_NEEDED_VENDOR_WORKORDERS_LIST: {
      const actionNeededVendorWorkOrders = propFromObjectArrayToFixed(
        action.actionNeededVendorWorkOrders,
        'ltreghrs',
      )
      const sortedWorkOrders = actionNeededVendorWorkOrders?.sort(
        function (a, b) {
          return new Date(a.repair_by) - new Date(b.repair_by)
        },
      )

      const filteredActionNeededVendorWorkOrders = sortedWorkOrders?.filter(
        (currentWO) => {
          return (
            currentWO.repair_by < tomorrow &&
            currentWO.external === '1' &&
            (currentWO.wovendworkval === 'Fail' ||
              currentWO.wovendworkval === null) &&
            currentWO.wotype !== 'ENVR'
          )
        },
      )

      const APFilteredActionNeededVendorWorkOrders = filterAPWorkorders(
        filteredActionNeededVendorWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        actionNeededVendorWorkOrders: APFilteredActionNeededVendorWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          actionNeededVendorWorkOrders: moment().format('LLL'),
        },
      }
      return newState
    }
    case SET_EMERGENCY_MAINTENANCE_WORKORDERS_LIST: {
      const emergencyMaintenanceWorkorders = propFromObjectArrayToFixed(
        action.emergencyMaintenanceWorkorders,
        'ltreghrs',
      )
      const sortedWorkOrders = emergencyMaintenanceWorkorders?.sort(
        function (a, b) {
          return new Date(a.repair_by) - new Date(b.repair_by)
        },
      )

      const filteredEmergencyMaintenanceWorkorders = sortedWorkOrders?.filter(
        (currentWO) => {
          const isNotValidated =
            currentWO.wovendworkval !== 'Pass' &&
            currentWO.wovendworkval !== 'Fail'

          return (
            currentWO.external === '1' &&
            currentWO.wotype === 'EM' &&
            isNotValidated
          )
        },
      )

      const APFilteredActionNeededVendorWorkOrders = filterAPWorkorders(
        filteredEmergencyMaintenanceWorkorders,
        state.userGroups,
      )

      const newState = {
        ...state,
        emergencyMaintenanceWorkorders: APFilteredActionNeededVendorWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          emergencyMaintenanceWorkorders: moment().format('LLL'),
        },
      }

      return newState
    }
    case SET_PREVENTATIVE_MAINTENANCE_WORKORDERS_LIST: {
      const preventativeMaintenanceWorkorders = propFromObjectArrayToFixed(
        action.preventativeMaintenanceWorkorders,
        'ltreghrs',
      )
      const sortedWorkOrders = sortListBasedOnUserGroup(
        preventativeMaintenanceWorkorders,
      )

      const filteredPreventativeMaintenanceWorkOrders =
        sortedWorkOrders?.filter((currentWO) => {
          return currentWO.external === '0' && currentWO.wotype === 'PM'
        })

      const APfilteredPreventativeMaintenanceWorkOrders = filterAPWorkorders(
        filteredPreventativeMaintenanceWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        preventativeMaintenanceWorkorders:
          APfilteredPreventativeMaintenanceWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          preventativeMaintenanceWorkorders: moment().format('LLL'),
        },
      }

      return newState
    }
    case SET_EXTERNAL_WORKORDERS_LIST: {
      const workOrders = propFromObjectArrayToFixed(
        action.externalWorkOrders,
        'ltreghrs',
      )

      const externalWOrders = workOrders.filter((wo) => {
        return (
          wo.external === '1' &&
          wo.wovendworkval !== 'Pass' &&
          wo.wotype !== 'ENVR'
        )
      })

      const sortedWorkOrders = externalWOrders.sort((a, b) => {
        return new Date(a.repair_by) - new Date(b.repair_by)
      })

      const APfilteredWorkOrders = filterAPWorkorders(
        sortedWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        externalWorkOrders: APfilteredWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          externalWorkOrders: moment().format('LLL'),
        },
      }

      return newState
    }
    case SET_REDIRECTED_WORKORDERS_LIST: {
      const workOrders = propFromObjectArrayToFixed(
        action.redirectedWorkOrders,
        'ltreghrs',
      )

      const redirectedWOrders = workOrders.filter((wo) => {
        const isNotValidated =
          wo.wovendworkval !== 'Pass' && wo.wovendworkval !== 'Fail'

        return wo.redirectreason != null && isNotValidated
      })

      const sortedWorkOrders = redirectedWOrders.sort((a, b) => {
        return new Date(a.repair_by) - new Date(b.repair_by)
      })

      const APfilteredWorkOrders = filterAPWorkorders(
        sortedWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        redirectedWorkOrders: APfilteredWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          redirectedWorkOrders: moment().format('LLL'),
        },
      }

      return newState
    }
    case SET_CM_EM_WORKORDERS_LIST: {
      const workOrders = propFromObjectArrayToFixed(
        action.cmWorkOrders,
        'ltreghrs',
      )

      const cmAndEmWorkOrders = workOrders.filter((wo) => {
        return (
          (wo.wotype === 'CM' && wo.external === '0') ||
          (wo.wotype === 'EM' && wo.external === '0')
        )
      })

      const sortedWorkOrders = sortListBasedOnUserGroup(cmAndEmWorkOrders)

      const APfilteredWorkOrders = filterAPWorkorders(
        sortedWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        cmWorkOrders: APfilteredWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          cmWorkOrders: moment().format('LLL'),
        },
      }
      return newState
    }
    case SET_CANCELLED_EXTERNAL_WORKORDERS_LIST: {
      const workOrders = propFromObjectArrayToFixed(
        action.cancelledExtWorkOrders,
        'ltreghrs',
      )

      const cancelledExtWorkOrders = workOrders.filter((wo) => {
        return wo.external === '1'
      })

      const sortedWorkOrders = cancelledExtWorkOrders.sort((a, b) => {
        return new Date(a.repair_by) - new Date(b.repair_by)
      })

      const APfilteredWorkOrders = filterAPWorkorders(
        sortedWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        cancelledExtWorkOrders: APfilteredWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          cancelledExtWorkOrders: moment().format('LLL'),
        },
      }
      return newState
    }
    case SET_SAFETY_WORKORDERS_LIST: {
      const workOrders = propFromObjectArrayToFixed(
        action.safetyWorkorders,
        'ltreghrs',
      )

      const safetyWorkorders = workOrders.filter((wo) => {
        return wo.haswltypesafety === '1'
      })

      const sortedWorkOrders = safetyWorkorders.sort((a, b) => {
        return new Date(a.repair_by) - new Date(b.repair_by)
      })

      const APfilteredWorkOrders = filterAPWorkorders(
        sortedWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        safetyWorkorders: APfilteredWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          safetyWorkorders: moment().format('LLL'),
        },
      }
      return newState
    }
    case SET_FIELD_PROJECT_WORKORDERS_LIST: {
      const fieldProjectWorkorders = propFromObjectArrayToFixed(
        action.fieldProjectWorkorders,
        'ltreghrs',
      )
      const sortedWorkOrders = sortListBasedOnUserGroup(fieldProjectWorkorders)

      const filteredFieldProjectWorkorders = sortedWorkOrders?.filter(
        (currentWO) => {
          return currentWO.external === '0' && currentWO.wotype === 'FP'
        },
      )

      const APfilteredWorkOrders = filterAPWorkorders(
        filteredFieldProjectWorkorders,
        state.userGroups,
      )

      const newState = {
        ...state,
        fieldProjectWorkorders: APfilteredWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          fieldProjectWorkorders: moment().format('LLL'),
        },
      }

      return newState
    }
    case SET_VENDOR_VALIDATION_WORKORDERS_LIST: {
      const vendorWorkValidationWOs = propFromObjectArrayToFixed(
        action.vendorWorkValidationWOs,
        'ltreghrs',
      )
      const sortedWorkOrders = vendorWorkValidationWOs?.sort(function (a, b) {
        return new Date(a.repair_by) - new Date(b.repair_by)
      })

      const filteredWorkOrders = sortedWorkOrders?.filter((currentWO) => {
        const isVendorWorkValidation =
          currentWO.wostatus === 'VCOMP' || currentWO.wostatus === 'VREPAIR'

        const isCorrectWorkType =
          currentWO.wotype !== 'ENVR' && currentWO.wotype !== 'INSP'

        const isNotValidated =
          currentWO.wovendworkval !== 'Pass' &&
          currentWO.wovendworkval !== 'Fail'

        return (
          currentWO.external === '1' &&
          isVendorWorkValidation &&
          isNotValidated &&
          isCorrectWorkType
        )
      })

      const APfilteredWorkOrders = filterAPWorkorders(
        filteredWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        vendorWorkValidationWOs: APfilteredWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          vendorWorkValidationWOs: moment().format('LLL'),
        },
      }
      return newState
    }
    case SET_AUTO_WORKORDERS_LIST: {
      const autoWorkorders = propFromObjectArrayToFixed(
        action.autoWorkorders,
        'ltreghrs',
      )
      const sortedWorkOrders = autoWorkorders?.sort(function (a, b) {
        return new Date(a.repair_by) - new Date(b.repair_by)
      })
      const filteredAutoWorkorders = sortedWorkOrders?.filter((currentWO) => {
        const isNotValidated =
          currentWO.wovendworkval !== 'Pass' &&
          currentWO.wovendworkval !== 'Fail'

        return (
          (currentWO.reportedby === 'SMRTMAXAWO' ||
            currentWO.reportedby === 'SVOPSTASKMANAGER') &&
          currentWO.wostatus !== 'CLOSED' &&
          currentWO.wostatus !== 'COMP' &&
          currentWO.wostatus !== 'CANCEL' &&
          isNotValidated
        )
      })

      const APfilteredAutoWorkOrders = filterAPWorkorders(
        filteredAutoWorkorders,
        state.userGroups,
      )

      const newState = {
        ...state,
        autoWorkorders: APfilteredAutoWorkOrders,
        lastUpdated: {
          ...state.lastUpdated,
          autoWorkorders: moment().format('LLL'),
        },
      }

      return newState
    }

    case SET_ASSIGNMENTS: {
      const newState = {
        ...state,
        assignments: action.assignments.map((assignment) => ({
          ...assignment,
        })),
      }
      return newState
    }

    case FLIP_TOGGLE: {
      const newState = {
        ...state,
        toggle: state.toggle === null ? true : !state.toggle, // changed logic here to not call assignments API when navigating to Open Work orders page everytime
      }
      return newState
    }

    default:
      return state
  }
}
