import moment from 'moment'
import { Tooltip } from '@mui/material'
import { DATE_NOT_SET, DATE_NOT_SET_TOOL_TIP_TEXT } from '../../globalConstants'

export const getDateRange = (
  startDateStr,
  lastDateStr,
  isReversed,
  tooltipText,
) => {
  const startDate = moment(startDateStr)
  const lastDate = moment(lastDateStr)

  const isStartDateValid = startDate.isValid()
  const isLastDateValid = lastDate.isValid()

  if (!isStartDateValid && !isLastDateValid) {
    return (
      <Tooltip title={tooltipText || DATE_NOT_SET_TOOL_TIP_TEXT} arrow>
        <span style={{ textDecoration: 'underline', cursor: 'help' }}>
          No dates specified
        </span>
      </Tooltip>
    )
  }

  const formatDateWithTooltip = (date, isValid) => {
    return isValid ? (
      date.format('MM/DD/YYYY')
    ) : (
      <Tooltip title={tooltipText || DATE_NOT_SET_TOOL_TIP_TEXT} arrow>
        <span style={{ textDecoration: 'underline', cursor: 'help' }}>
          {DATE_NOT_SET}
        </span>
      </Tooltip>
    )
  }

  const formattedStartDate = formatDateWithTooltip(startDate, isStartDateValid)
  const formattedLastDate = formatDateWithTooltip(lastDate, isLastDateValid)

  return (
    <>
      {isReversed ? formattedLastDate : formattedStartDate}
      <span style={{ marginRight: '2px', marginLeft: '2px' }}>-</span>
      {isReversed ? formattedStartDate : formattedLastDate}
    </>
  )
}
