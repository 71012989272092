/* eslint-disable react-hooks/exhaustive-deps */
import { getURLSearchParams, setURLSearchParams } from '../../windowManager'
import { useState, useEffect, useRef } from 'react'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  Dialog,
  Stack,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import MatAppBar from '../MatAppBar/MatAppBar'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Alert } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { useAuth } from '@praxis/component-auth'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData, postData, putData } from '../../service/HttpService'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { STOREROOM_ITEMS } from '../../globalConstants'
import ImageNotFound from '../../assets/imageNotFound.png'
import CloseIcon from '@mui/icons-material/Close'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import useHashChange from '../../utils/hooks/useHashChange'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { pollCycleCountPropagation } from '../../utils/poll'

const CycleCountDetail = ({ usePoll = true }) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      table: {
        '& .MuiTableCell-root': {
          width: '50%',
        },
      },
      inputField: {
        marginTop: theme.spacing(3),
        width: '100%',
      },
      warningError: {
        margin: theme.spacing(1),
      },
      closeIcon: {
        position: 'absolute',
        right: theme.spacing(0.5),
        top: theme.spacing(0.5),
      },
      thumbnail: {
        height: 100,
        cursor: 'pointer',
        margin: theme.spacing(1, 3, 0, 0),
      },
      fullImage: {
        maxWidth: '100%',
        maxHeight: '100%',
      },
      dialogTitle: {
        padding: theme.spacing(1, 0),
      },
      imageDialog: {
        textAlign: 'center',
      },
      dialogContent: {
        justifyContent: 'left',
        paddingTop: theme.spacing(2),
      },
      iconButton: {
        margin: theme.spacing(2.5, 0, 0, 1),
      },
    }),
  )
  const classes = useStyles()
  const commonTableProps = {
    size: 'medium',
    className: classes.table,
  }
  const auth = useAuth()
  const dispatch = useDispatch()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const {
    storeId = '',
    userStoreroom,
    shouldRender,
  } = useSelector((state) => ({
    ...state.storeInfoReducer,
    ...state.userInfoReducer,
    ...state.progressOverlayReducer,
  }))
  const [error, setError] = useState(false)
  const [partCount, setPartCount] = useState(0)
  const urlSearchParams = getURLSearchParams()
  const itemnum = urlSearchParams.get('itemnum')
  const binnum = urlSearchParams.get('binnum')
  const issueUnit = urlSearchParams.get('issueunit')
  const description = urlSearchParams.get('description')
  const balance = parseInt(urlSearchParams.get('balance'))
  const countDate = urlSearchParams.get('countdate')
  const itemid = urlSearchParams.get('itemid')
  const [showImageDialog, setShowImageDialog] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [itemImage, setItemImage] = useState()
  const inputFile = useRef(null)
  useHashChange('image_open', setShowImageDialog)

  const countPart = async () => {
    const messages = [
      'Submitting Count',
      'Please wait while data fully propagates',
    ]

    const requestBody = {
      binnum: binnum,
      curbal: balance,
      itemnum: itemnum,
      physcount: partCount,
      siteid: storeId,
      user: userId,
    }

    try {
      dispatch(setShouldRender(true, messages[0]))
      await postData(`/maximo/item/count/`, JSON.stringify(requestBody))
      if (usePoll) {
        dispatch(setShouldRender(true, messages[1]))
        await pollCycleCountPropagation(userStoreroom, requestBody)
      }
      window.history.back()
    } catch (error) {
      setError(error)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const countExceedsFiftyPercent = () => {
    return (
      ((partCount > 9 || balance > 9) && partCount > balance + balance / 2) ||
      ((partCount > 9 || balance > 9) && partCount < balance / 2)
    )
  }
  useEffect(() => {
    async function loadImage() {
      dispatch(setShouldRender(true, 'Loading Image...'))
      setImageError(false)
      try {
        const response = await fetchData(`/mam/image/itemnum/${itemnum}`)
        const image = response.image_data[0]?.image
        setItemImage(image)
      } catch (error) {
        setImageError(true)
      } finally {
        dispatch(setShouldRender(false))
      }
    }
    loadImage()
  }, [])

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result.replace(/^data:image\/[a-z]+;base64,/, ''))
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const onUploadPhotoClick = () => {
    inputFile.current.click()
  }

  const addOrUpdateImage = async (e) => {
    const file = e?.target?.files[0] ?? {}
    const convertedFile = await convertToBase64(file)
    const url = `/maximo/image/`

    const dataObject = {
      itemnum: itemnum,
      image: convertedFile,
      itemid: itemid ?? '',
      userid: userId,
    }
    var objectJson = JSON.stringify(dataObject)

    try {
      dispatch(
        setShouldRender(true, itemImage ? 'Updating Image' : 'Adding Image'),
      )
      !itemImage
        ? await postData(url, objectJson)
        : await putData(url, objectJson)

      setItemImage(convertedFile)
    } catch (error) {
      setImageError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const openImageModal = (data) => {
    setURLSearchParams([{ name: 'image_open', value: true }])
  }
  const handleClose = () => {
    window.history.back()
  }

  const handleIncrease = () => {
    setPartCount(partCount + 1)
  }

  const handleDecrease = () => {
    partCount >= 1 && setPartCount(Math.max(partCount - 1, 0))
  }

  return (
    <>
      <MatAppBar
        arrowBack
        text={STOREROOM_ITEMS(userStoreroom)}
        minimizeHeader
      />
      {imageError && !shouldRender && (
        <Alert severity="error" className={classes.warningError}>
          Error retrieving/saving part image
        </Alert>
      )}
      {error && !shouldRender && (
        <Alert severity="error" className={classes.warningError}>
          There was an error submitting the count.
        </Alert>
      )}
      {!shouldRender && (
        <>
          {!imageError && (
            <Stack direction="row" justifyContent="center">
              <img
                className={classes.thumbnail}
                src={
                  itemImage
                    ? `data:image/jpeg;base64,${itemImage}`
                    : ImageNotFound
                }
                alt="test"
                onClick={() => openImageModal(itemImage)}
              />
              <IconButton
                aria-label="add-photo-button"
                color="primary"
                onClick={onUploadPhotoClick}
              >
                <AddAPhotoIcon />
              </IconButton>
            </Stack>
          )}
          <Table {...commonTableProps}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <strong>{description}</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Bin</TableCell>
                <TableCell>{binnum}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Unit</TableCell>
                <TableCell>{issueUnit}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Last Counted</TableCell>
                <TableCell>{countDate}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Stack direction="row">
            <NumericFormat
              className={classes.inputField}
              autoFocus
              label={`Count item ${itemnum} by ${issueUnit}`}
              customInput={TextField}
              value={partCount}
              onFocus={(event) => {
                event.target.select()
              }}
              InputLabelProps={{ shrink: true }}
              type="text"
              onChange={(e) => setPartCount(Number(e.target.value))}
              decimalScale={2}
              allowNegative={false}
              allowLeadingZeros={false}
              variant="outlined"
              inputProps={{ 'data-testid': 'input-field' }}
              error={countExceedsFiftyPercent()}
              helperText={
                countExceedsFiftyPercent() &&
                `Count has large variance from current balance.`
              }
            />
            <IconButton
              color="primary"
              onClick={handleIncrease}
              className={classes.iconButton}
            >
              <AddCircleIcon data-testid="increase-count" fontSize="large" />
            </IconButton>
            <IconButton
              color="primary"
              onClick={handleDecrease}
              className={classes.iconButton}
            >
              <RemoveCircleIcon data-testid="decrease-count" fontSize="large" />
            </IconButton>
          </Stack>
          <DialogActions className={classes.dialogContent}>
            <Button
              data-testid="cancel-button"
              variant="outlined"
              onClick={() => window.history.back()}
            >
              Cancel
            </Button>
            <Button
              data-testid="save-button"
              variant="contained"
              onClick={() => countPart()}
              disabled={
                partCount === null ||
                partCount === undefined ||
                partCount === isNaN()
              }
            >
              &nbsp;&nbsp;Save &nbsp;&nbsp;
            </Button>
          </DialogActions>
        </>
      )}
      <Dialog
        open={showImageDialog}
        onClose={() => setShowImageDialog(false)}
        aria-labelledby="image-dialog-title"
        aria-describedby="image-dialog-description"
        fullScreen
        className={classes.imageDialog}
      >
        <DialogTitle id="image-dialog-title">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent id="image-dialog-description">
          <Typography className={classes.dialogTitle}>{description}</Typography>

          <img
            src={
              itemImage ? `data:image/jpeg;base64,${itemImage}` : ImageNotFound
            }
            alt="test"
            className={classes.fullImage}
          />
        </DialogContent>
      </Dialog>
      <input
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        id="image-upload"
        data-testid="image-upload-input"
        ref={inputFile}
        className={classes.photoUploadInput}
        onChange={addOrUpdateImage}
        hidden
        multiple={false}
      />
    </>
  )
}

export default CycleCountDetail
