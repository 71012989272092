/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
} from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { makeStyles, createStyles } from '@mui/styles'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { fetchData, postData } from '../../service/HttpService'
import MatAppBar from '../MatAppBar/MatAppBar'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { setTimeEntries } from './store/actionCreator'
import AddTimeModal from '../AddTimeModal/AddTimeModal'
import {
  api,
  LOADING_TIME_ENTRIES,
  TIME_TRACKING,
  LABOR_ENTRIES_KEY,
  TIME_TRACKING_SERVICE_ERROR,
  TIME_TRACKING_EMPTY,
  ASSET_DESC_OR_LOCATION,
  DESCRIPTION,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_DATE_FORMAT,
} from '../../globalConstants'
import ExpandMore from '@mui/icons-material/ExpandMore'
import moment from 'moment'
import { formattedTime, setTimeString, addIndividualTimes } from '../../utils'
import { COLOR_INDIGO, COLOR_GREY_CONTRAST_WEAK } from '../../globalColors'
import { setURLSearchParams } from '../../windowManager'
import useHashChange from '../../utils/hooks/useHashChange'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { COLOR_DEFAULT_GREY } from '../../globalColors'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(1, 0, 2, 2),
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    cardHeader: {
      background: theme.palette.grey[300],
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    accordionSummaryContent: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    accordionSummary: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
    },
    content: {
      marginTop: theme.spacing(2),
    },
    accordion: {
      border: `1px solid ${theme.palette.divider}`,
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    },
    headerContent: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerLeft: {
      display: 'flex',
      '@media(max-width:600px)': {
        flexDirection: 'column',
      },
    },
    accorionSummaryRight: {
      margin: theme.spacing(0, 2),
    },
    alertInfo: {
      margin: theme.spacing(1),
      alignItems: 'center',
      borderColor: COLOR_DEFAULT_GREY,
      '& .MuiAlert-icon > svg': {
        fill: COLOR_DEFAULT_GREY,
      },
    },
    alertAction: {
      paddingTop: 0,
    },
  }),
)

const groupTimeEntriesByDate = (data) => {
  const result = data.reduce((timeEntries, timeEntry) => {
    const date = timeEntry.startdate.split(' ')[0]
    if (!timeEntries[date]) {
      timeEntries[date] = []
    }
    timeEntries[date].push(timeEntry)
    return timeEntries
  }, {})
  return result
}

const getHeaderDateRange = (sortedTimeEntries) => {
  const startDate = moment(sortedTimeEntries?.[0]).format('MM/DD/YYYY') ?? ''
  const endDate =
    moment(sortedTimeEntries?.[sortedTimeEntries.length - 1]).format(
      'MM/DD/YYYY',
    ) ?? ''
  return startDate && endDate ? (
    <>
      <div>Total Time for&nbsp; </div>
      <div>{`${startDate ? `${startDate} - ` : ''}${endDate}`}</div>
    </>
  ) : null
}
export default function TimeTracking() {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { storeId = '', timeEntries } = useSelector((state) => ({
    ...state.storeInfoReducer,
    ...state.timeTrackingReducer,
  }))
  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId
  const [filteredData, setFilteredData] = useState({})
  const sortedTimeEntries = Object.keys(filteredData)
  const [dayLaborTime, setDayLaborTime] = useState(0.0)
  const [todayLaborTime, setTodayLaborTime] = useState(0.0)
  const [collapsed, setCollapsed] = useState(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [laborId, setLaborId] = useState('')
  const [laborType, setLaborType] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [timeToEdit, setTimeToEdit] = useState(null)
  const [dateToEdit, setDateToEdit] = useState(null)
  const [woNumToEdit, setWoNumToEdit] = useState('')
  useHashChange('delete_open', setDeleteModalOpen)
  const dispatch = useDispatch()
  const currDayAccordionRef = useRef(null)
  const grandTotalTime = setTimeString(
    addIndividualTimes(Object?.values(filteredData)?.flat(), 'time'),
  )
  const today = moment().format(DEFAULT_DATE_FORMAT)

  useEffect(() => {
    calculateDayLaborTimeFloat(today)
  })

  useEffect(() => {
    const getLaborEntries = async () => {
      try {
        dispatch(setShouldRender(true, LOADING_TIME_ENTRIES))
        setLoading(true)
        const response = await fetchData(
          api.laborEntries(storeId, userId?.toUpperCase()),
        )
        const myTimeEntries = response?.[LABOR_ENTRIES_KEY] ?? []
        dispatch(setTimeEntries(myTimeEntries))
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
        dispatch(setShouldRender(false))
      }
    }
    getLaborEntries()
  }, [])

  useEffect(() => {
    const requiredData = groupTimeEntriesByDate(timeEntries)
    setFilteredData(requiredData)
    const accordionState = Object.fromEntries(
      Object.keys(requiredData).map((val) => [val, false]),
    )
    const currDate = moment().format(DEFAULT_DATE_FORMAT)
    setCollapsed({ ...accordionState, [currDate]: true })
    if (currDayAccordionRef?.current) {
      currDayAccordionRef?.current?.scrollIntoView?.({ behavior: 'smooth' })
    }
  }, [timeEntries, currDayAccordionRef?.current])

  const deleteRecord = async () => {
    const requestBody = {
      'labortrans-id': laborId,
      'labor-code': userId,
    }

    try {
      dispatch(setShouldRender(true, 'Deleting Record..'))
      await postData('/maximo/labortransaction/delete', requestBody)

      const response = await fetchData(
        api.laborEntries(storeId, userId?.toUpperCase()),
      )
      const myTimeEntries = response?.[LABOR_ENTRIES_KEY] ?? []
      dispatch(setTimeEntries(myTimeEntries))

      setError(false)
      window.history.back()
    } catch (error) {
      setError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const handleClick = (val) => {
    setCollapsed({ ...collapsed, [val]: !collapsed[val] })
  }

  const calculateDayLaborTimeFloat = (date) => {
    const formattedDate = moment(date).format(DEFAULT_DATE_FORMAT)
    for (const [arrayName] of Object.entries(filteredData)) {
      let sectionTimeTotal = 0.0
      if (arrayName.includes(formattedDate)) {
        filteredData[arrayName].forEach((laborEntry) => {
          if (laborEntry?.time) {
            sectionTimeTotal += parseFloat(laborEntry.time)
          }
        })
        if (date === today) {
          setTodayLaborTime(sectionTimeTotal)
        } else {
          setDayLaborTime(sectionTimeTotal)
        }
      }
    }
  }

  const handleEdit = (row) => {
    setIsEditing(true)
    setURLSearchParams([{ name: 'add_time_open', value: true }])
    setDateToEdit(row.startdate)
    setTimeToEdit(row.time)
    setLaborId(row.laborid)
    setLaborType(row.labortype)
    calculateDayLaborTimeFloat(row.startdate)
    setWoNumToEdit(row.wonum)
  }

  function renderTimeEntryTable(data) {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{DESCRIPTION}</strong>
              </TableCell>
              <TableCell>
                <strong>Time</strong>
              </TableCell>
              <TableCell>
                <strong>{ASSET_DESC_OR_LOCATION}</strong>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              const loggedTime = setTimeString(formattedTime(row?.time))
              const description =
                row?.labortype !== 'WORK' ? (
                  (row?.labortype ?? '')
                ) : (
                  <Link
                    href={`/work-order-details#?wonum=${row?.wonum}&showBack=true`}
                  >
                    {row?.wodesc ?? ''}
                  </Link>
                )
              const asset = row?.assetName ? <div>{row?.assetName}/</div> : ''
              const areaOfStore = row?.location ? (
                <div>{row?.location}</div>
              ) : (
                ''
              )

              return (
                <TableRow
                  key={row.laborid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{description}</TableCell>
                  <TableCell padding="none">{loggedTime}</TableCell>
                  <TableCell>
                    {asset}
                    {areaOfStore}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row">
                      <IconButton
                        variant="outlined"
                        aria-label="Edit Time"
                        onClick={() => handleEdit(row)}
                        sx={{
                          color: COLOR_INDIGO,
                          backgroundColor: COLOR_GREY_CONTRAST_WEAK,
                          borderColor: COLOR_INDIGO,
                          '&:hover': {
                            backgroundColor: COLOR_GREY_CONTRAST_WEAK,
                          },
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        variant="outlined"
                        aria-label="Delete Time"
                        onClick={() => {
                          setURLSearchParams([
                            { name: 'delete_open', value: true },
                          ])
                          setLaborId(row.laborid)
                        }}
                        sx={{
                          color: COLOR_INDIGO,
                          backgroundColor: COLOR_GREY_CONTRAST_WEAK,
                          borderColor: COLOR_INDIGO,
                          '&:hover': {
                            backgroundColor: COLOR_GREY_CONTRAST_WEAK,
                          },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  function renderCollapsedTimeEntriesByDate() {
    const sortedEntriesDates = sortedTimeEntries.sort(
      (a, b) => moment(b) - moment(a),
    )

    return sortedEntriesDates.map((val, idx) => {
      const sectionTimeTotal = setTimeString(
        addIndividualTimes(filteredData[val], 'time'),
      )
      const currDate = moment().format(DEFAULT_DATE_FORMAT)
      return (
        <Accordion
          expanded={collapsed[val]}
          onChange={() => handleClick(val)}
          key={val}
          TransitionProps={{ unmountOnExit: true }}
          className={classes.accordion}
          ref={(el) => {
            if (currDate === val && el) {
              currDayAccordionRef.current = el
            }
          }}
          data-testid={`accordion-${idx}`}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryContent}>
              <Typography>
                {moment(val).format('dddd, MMM DD, YYYY')}
              </Typography>
              <Typography className={classes.accorionSummaryRight}>
                {`${sectionTimeTotal}`}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {renderTimeEntryTable(filteredData[val])}
          </AccordionDetails>
        </Accordion>
      )
    })
  }

  return (
    <>
      <AddTimeModal
        timeTracking
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        dateToEdit={dateToEdit}
        timeToEdit={timeToEdit}
        laborId={laborId}
        laborType={laborType}
        dayLaborTime={dayLaborTime}
        todayLaborTime={todayLaborTime}
        filteredData={filteredData}
        woNumToEdit={woNumToEdit}
      />
      <MatAppBar arrowBack text={TIME_TRACKING} />

      <Alert
        severity="info"
        variant="outlined"
        className={classes.alertInfo}
        data-testid="info-alert"
        classes={{ action: classes.alertAction }}
        action={<div>{grandTotalTime}</div>}
      >
        <div className={classes.headerLeft}>
          {getHeaderDateRange(sortedTimeEntries)}
        </div>
      </Alert>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="outlined"
          startIcon={<AddBoxIcon />}
          aria-label="Add Time"
          onClick={() =>
            setURLSearchParams([{ name: 'add_time_open', value: true }])
          }
          sx={{
            marginRight: 1,
            marginLeft: 1,
            color: COLOR_INDIGO,
            backgroundColor: COLOR_GREY_CONTRAST_WEAK,
            borderColor: COLOR_INDIGO,
            '&:hover': {
              backgroundColor: COLOR_GREY_CONTRAST_WEAK,
            },
          }}
        >
          {'Time'}
        </Button>
      </Grid>
      <div className={classes.content}>
        {renderCollapsedTimeEntriesByDate()}
      </div>
      <Dialog open={deleteModalOpen} onClose={() => window.history.back()}>
        <DialogTitle>Delete</DialogTitle>
        {error && <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item>
              <Typography>
                Are you sure you want to delete this record?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => window.history.back()}>
            No
          </Button>
          <Button variant="contained" onClick={deleteRecord}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {error && !loading && (
        <Alert severity="error">{TIME_TRACKING_SERVICE_ERROR}</Alert>
      )}
      {!error && !loading && !timeEntries.length && (
        <Alert severity="warning">{TIME_TRACKING_EMPTY}</Alert>
      )}
    </>
  )
}
