/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MatAppBar from '../MatAppBar/MatAppBar'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useAuth } from '@praxis/component-auth'
import {
  TextField,
  Grid,
  Typography,
  Card,
  CardActionArea,
  Divider,
  CardContent,
  CardHeader,
  List,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button,
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { makeStyles, createStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'
import moment from 'moment'
import {
  api,
  ASSET_NAME,
  AREA_OF_STORE,
  NOT_AVAILABLE,
  globalRoutes,
  DEFAULT_DATE_FORMAT,
  SCHEDULES,
  DATE_FORMAT,
  WO_TYPE,
  XS_CUSTOM_BREAKPOINT,
  XS_CARD_MEDIA_QUERY,
  ASSET_TAG,
  LOCATION,
  NO_SCHEDULES,
  SCHEDULE_OR_ASSIGNMENT,
  DATE_NOT_SET,
} from '../../globalConstants'
import {
  COLOR_BACKGROUND_GREY,
  COLOR_DEFAULT_GREY,
  COLOR_TARGET_RED,
  COLOR_WARNING_YELLOW,
} from '../../globalColors'
import { useNavigate } from 'react-router-dom'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { fetchDataParallel } from '../../service/HttpService'
import { setAssignments } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import {
  setCollapsedInStore,
  setFromDate,
  setMyShifts,
  setOtherShifts,
  setShiftKeys,
  setToDate,
} from './store/actionCreator'
import { Close } from '@mui/icons-material'
import {
  convertTimestampToLocal,
  addIndividualTimes,
  setTimeString,
} from '../../utils'
import ToggleScheduleButton from '../WorkOrderList/ToggleScheduleButton'
import WarningIcon from '@mui/icons-material/Warning'
import { getRepairByAlertDate } from '../../utils/hooks/workOrderUtil'
import WorkOrderItem from '../WorkOrderItem/WorkOrderItem'
import SortIcon from '@mui/icons-material/Sort'
import ScheduleShifts from './ScheduleShifts'
import { getURLSearchParams } from '../../windowManager'
import { getDateRange } from './ScheduleHelpers'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing(0, 1, 1, 1),
      [XS_CUSTOM_BREAKPOINT]: XS_CARD_MEDIA_QUERY,
      '& .MuiCardActions-root': {
        padding: theme.spacing(0, 1, 1, 1),
      },
      '& .MuiCardContent-root': {
        padding: theme.spacing(1),
      },
    },
    cardHeader: {
      background: theme.palette.grey[300],
      padding: theme.spacing(0.25, 1),
      fontWeight: 'bold',
    },
    cardHeaderRed: {
      backgroundColor: COLOR_TARGET_RED,
      color: 'white',
    },
    cardHeaderYellow: {
      backgroundColor: COLOR_WARNING_YELLOW,
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    alertInfo: {
      margin: theme.spacing(1, 0),
      alignItems: 'center',
      borderColor: COLOR_DEFAULT_GREY,
      '& .MuiAlert-icon > svg': {
        fill: COLOR_DEFAULT_GREY,
      },
    },
    alertAction: {
      paddingTop: 0,
    },
    headerLeft: {
      display: 'flex',
      '@media(max-width:500px)': {
        flexDirection: 'column',
      },
    },
    accordionSummaryContent: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      backgroundColor: COLOR_BACKGROUND_GREY,
    },
    content: {
      marginTop: theme.spacing(2),
    },
    accordion: {
      border: `1px solid ${theme.palette.divider}`,
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    },
    headerContent: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    accorionSummaryRight: {
      margin: theme.spacing(0, 2),
    },
    dateFilterDiv: {
      display: 'flex',
      gap: theme.spacing(4),
      '@media(max-width:460px)': {
        gap: theme.spacing(1),
      },
    },
    filterBorder: {
      borderRadius: '10px',
      borderLeftWidth: '1px',
      borderRightWidth: '1px',
      position: 'relative',
      outline: '0',
      margin: 'auto',
      padding: theme.spacing(3),
      backgroundColor: '#fff',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '@media(max-width:460px)': {
        padding: theme.spacing(3, 0.5, 3, 0.5),
      },
    },
    myWorkHeader: {
      margin: theme.spacing(2, 1, 0, 1),
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    marginAccordion: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    alertWrap: {
      wordBreak: 'break-word',
    },
  }),
)

const sortByDateField = (data, field) => {
  return data.sort((a, b) => new Date(a[field]) - new Date(b[field]))
}

const groupSchedulesByDate = (data) => {
  const isReversed = JSON.parse(localStorage.getItem('reverseSchedule'))
  isReversed && data.reverse()
  const result = data.reduce((wogroups, wo) => {
    const woScheduleDate = wo.scheduledate
    const date = woScheduleDate ? woScheduleDate?.split(' ')[0] : ''
    if (!wogroups[date]) {
      wogroups[date] = []
    }
    wogroups[date].push(wo)
    return wogroups
  }, {})

  let finalResult = {}
  Object.keys(result).forEach((val) => {
    finalResult[val] = sortByDateField(result[val], 'scheduledate')
  })
  return result
}

const Schedules = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [isReversed, setIsReversed] = useState(
    JSON.parse(localStorage.getItem('reverseSchedule')),
  )
  const auth = useAuth()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const { storeId, fromDate, toDate, collapsedInStore, shiftKeys, isDCUser } =
    useSelector((state) => ({
      ...state.workOrderListReducer,
      ...state.storeInfoReducer,
      ...state.schedulesReducer,
      ...state.userInfoReducer,
    }))
  const rawAssignments = useSelector(
    (state) => state.workOrderListReducer.assignments,
  )
  const assignments = useMemo(() => rawAssignments || [], [rawAssignments])

  const urlSearchParams = getURLSearchParams()
  const userNumber = urlSearchParams.get('user')
  const name = urlSearchParams.get('name')
  const [userNum, setUserNum] = useState(userNumber ?? userId)
  const [userName, setUserName] = useState(name ?? '')
  const onHashChange = () => {
    const user = urlSearchParams.get('user')
    const otherUserName = urlSearchParams.get('name')
    setUserNum(user)
    setUserName(otherUserName)
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  })
  const headerDates = useRef(null)
  const sortedScheduleData = (data = filteredData) =>
    isReversed
      ? Object.keys(data).sort((a, b) => new Date(b) - new Date(a))
      : Object.keys(data).sort((a, b) => new Date(a) - new Date(b))
  const [collapsed, setCollapsed] = useState(
    collapsedInStore ||
      Object.fromEntries(Object.keys(filteredData).map((val) => [val, false])),
  )

  const handleClick = (val) => {
    const updatedCollapsed = { ...collapsed, [val]: !collapsed[val] }
    setCollapsed(updatedCollapsed)
    dispatch(setCollapsedInStore(updatedCollapsed))
  }

  const handleFromDateChange = (newValue) => {
    dispatch(setFromDate(moment(newValue).format(DEFAULT_DATE_FORMAT)))
    dispatch(setToDate(null))
  }
  const handleToDateChange = (newValue) => {
    dispatch(setToDate(moment(newValue).format(DEFAULT_DATE_FORMAT)))
  }

  const getAssignments = async () => {
    try {
      dispatch(
        setShouldRender(true, 'Loading...', SCHEDULE_OR_ASSIGNMENT(isDCUser)),
      )
      const apiCall = [
        api.myAssignments(storeId, userNum ?? userId),
        api.otherShifts(userId),
        api.myShift(userId),
      ]
      const response = await fetchDataParallel(apiCall)
      const assignments = response[0].assignmentsdata
      const otherShifts = response[1].otherShiftsList
      const myShifts = response[2].myShiftList

      dispatch(setAssignments(assignments))
      dispatch(setMyShifts(myShifts))
      dispatch(setOtherShifts(otherShifts))

      const myShiftKeys = [...new Set(myShifts.map((item) => item.shiftNum))]
      const otherShiftKeys = [
        ...new Set(otherShifts.map((item) => item.shiftNum)),
      ]
      dispatch(setShiftKeys(myShiftKeys.concat(otherShiftKeys)))

      headerDates.current = {
        startDate: !isDCUser ? fromDate : null,
        lastDate: !isDCUser ? toDate : null,
      }
    } catch (e) {
      setError(SCHEDULE_OR_ASSIGNMENT(isDCUser))
    } finally {
      dispatch(setShouldRender(false))
      setLoading(false)
      window.scroll({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    getAssignments()
  }, [userNum])

  useEffect(() => {
    if (isDCUser || (fromDate === null && toDate === null)) {
      const groupedData = groupSchedulesByDate(assignments)
      setFilteredData(groupedData)
      const sortedData = sortedScheduleData(groupedData)
      headerDates.current = {
        startDate: sortedData[0],
        lastDate: sortedData[sortedData.length - 1],
      }
      setCollapsed(
        Object.fromEntries(
          Object.keys(groupedData).map((val) => [
            val,
            Object?.keys?.(collapsedInStore ?? {})?.find?.(
              (innerval) => innerval === val,
            )
              ? collapsedInStore[val]
              : isDCUser
                ? false
                : true,
          ]),
        ),
      )
    }
    if (fromDate && toDate && assignments?.length > 0 && !isDCUser) {
      const filteredWorkOrders = assignments.filter((val) => {
        const woDate = val['scheduledate']?.split(' ')[0]
        const formattedWoDate = moment(woDate).format(DEFAULT_DATE_FORMAT)
        if (new Date(fromDate) !== new Date(toDate)) {
          return (
            new Date(formattedWoDate) >= new Date(fromDate) &&
            new Date(formattedWoDate) <= new Date(toDate)
          )
        } else {
          return new Date(formattedWoDate) === new Date(fromDate)
        }
      })
      const finalData = groupSchedulesByDate(filteredWorkOrders)
      setFilteredData(finalData)
      setCollapsed(
        Object.fromEntries(
          Object.keys(finalData).map((val) => [
            val,
            Object?.keys?.(collapsedInStore ?? {})?.find?.(
              (innerval) => innerval === val,
            )
              ? collapsedInStore[val]
              : true,
          ]),
        ),
      )
      headerDates.current = {
        startDate: fromDate,
        lastDate: toDate,
      }
    }
  }, [fromDate, toDate, assignments, isReversed])

  const commonGridItemProps = {
    item: true,
    xs: 5,
  }

  const changeHeaderColor = (repairby) => {
    const hours = getRepairByAlertDate(repairby)
    let headerProp = `${classes.cardHeader}`
    if (hours <= 24 && repairby && !isDCUser) {
      headerProp = `${classes.cardHeaderRed}`
    } else if (hours <= 72 && hours > 24 && repairby && !isDCUser) {
      headerProp = `${classes.cardHeaderYellow}`
    }
    return headerProp
  }

  const displayWarningIcon = (repairby) => {
    const hours = getRepairByAlertDate(repairby)
    let iconColor = ''
    if (hours > 72 || repairby === null) {
      return
    } else if (hours <= 24) {
      iconColor = COLOR_TARGET_RED
    } else if (hours <= 72 && hours > 24) {
      iconColor = COLOR_WARNING_YELLOW
    }
    return (
      <WarningIcon
        fontSize="small"
        sx={{ color: iconColor, verticalAlign: 'text-top' }}
      />
    )
  }

  const toggleSorting = () => {
    localStorage.setItem('reverseSchedule', !isReversed)
    setIsReversed(!isReversed)
  }

  const getAssetLabel = (workorder) => {
    return isDCUser
      ? workorder?.assettag
        ? ASSET_TAG
        : LOCATION
      : workorder?.woassetname
        ? ASSET_NAME
        : AREA_OF_STORE
  }

  const getAssetInfo = (workorder) => {
    const assetTag = workorder?.assettag
    const assetName = workorder?.woassetname
    const location = workorder?.wolocation
    return isDCUser
      ? assetTag
        ? assetTag
        : location
      : assetName
        ? assetName
        : location
  }
  const myScheduleButton = () => {
    return (
      <Button
        onClick={() => navigate(`${globalRoutes.schedules}`)}
        variant="outlined"
      >
        My Work
      </Button>
    )
  }

  function renderCards(data) {
    return (
      <Grid container>
        {data.map((workorder, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            className={classes.cardContent}
            key={index}
            data-testid="workorder-list"
          >
            <Card>
              <CardActionArea
                onClick={() =>
                  navigate(
                    `${globalRoutes.workOrderDetails}/schedules#?wonum=${workorder.wonum}&showBack=true&workorderId=${workorder.woid}`,
                  )
                }
              >
                <CardHeader
                  className={
                    classes.cardHeader +
                    ' ' +
                    changeHeaderColor(workorder.repairby)
                  }
                  action={<ChevronRightIcon className={classes.actionIcon} />}
                  title={<strong>WO # {workorder.wonum}</strong>}
                  titleTypographyProps={{ variant: 'subtitle1' }}
                />
                <Divider />
                <CardContent sx={{ padding: '12px' }}>
                  <Grid container>
                    <Grid>
                      <Typography>{workorder.wodesc}</Typography>
                    </Grid>
                  </Grid>
                  <WorkOrderItem
                    label={getAssetLabel(workorder)}
                    value={getAssetInfo(workorder)}
                  />
                  {workorder?.woassetname && (
                    <WorkOrderItem
                      label={isDCUser ? LOCATION : AREA_OF_STORE}
                      value={workorder.wolocation}
                    />
                  )}
                  <Grid container>
                    <Grid {...commonGridItemProps}>
                      <Typography>
                        <strong>Sch Start:</strong>
                      </Typography>
                    </Grid>
                    <Typography>
                      {workorder.scheduledate
                        ? convertTimestampToLocal(
                            workorder.scheduledate,
                            null,
                            'timezone',
                          )
                        : NOT_AVAILABLE}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid {...commonGridItemProps}>
                      <Typography>
                        <strong>Sch End:</strong>
                      </Typography>
                    </Grid>
                    <Typography>
                      {workorder.scheduledate
                        ? moment(
                            convertTimestampToLocal(
                              workorder.scheduledate,
                              null,
                              'timezone',
                              'LLLFormat',
                            ),
                            moment.ISO_8601, // to fix moment warning about deprecated format
                            true,
                          )
                            .add(workorder.laborHrs || 0, 'hours')
                            .format(DATE_FORMAT)
                        : NOT_AVAILABLE}
                    </Typography>
                  </Grid>
                  {!isDCUser && (
                    <Grid container>
                      <Grid {...commonGridItemProps}>
                        <Typography>
                          <strong>
                            Repair by:{displayWarningIcon(workorder.repairby)}
                          </strong>
                        </Typography>
                      </Grid>
                      <Typography>
                        {workorder.repairby
                          ? convertTimestampToLocal(workorder.repairby)
                          : NOT_AVAILABLE}
                      </Typography>
                    </Grid>
                  )}

                  <WorkOrderItem label={WO_TYPE} value={workorder.wotype} />
                </CardContent>
              </CardActionArea>
              <ToggleScheduleButton
                workorder={workorder}
                woLength={1}
                callback={() => getAssignments()}
                from={SCHEDULES}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }

  function renderCollapsedWOByDate() {
    return (
      <>
        {userName && (
          <Alert
            severity="info"
            variant="outlined"
            action={myScheduleButton()}
            className={classes.content}
          >
            <span className={classes.alertWrap}>
              {`You are currently viewing ${userName}'s ${SCHEDULE_OR_ASSIGNMENT(isDCUser)}.`}
            </span>
          </Alert>
        )}
        {isDCUser && (
          <Typography variant="h6" className={classes.myWorkHeader}>
            {userName ? userName : `My Work`}
          </Typography>
        )}
        {assignments?.length > 0 && sortedScheduleData()?.length === 0 && (
          <Alert
            severity="warning"
            data-testid={'no-data-text'}
            className={classes.content}
          >
            {NO_SCHEDULES(isDCUser)}
          </Alert>
        )}

        {!loading && assignments.length > 0 && (
          <List>
            {sortedScheduleData().map((val, index) => {
              const totalWorkOrders = filteredData[val]?.length
              const totalLaborHours = setTimeString(
                addIndividualTimes(filteredData[val], 'laborHrs'),
              )

              return (
                <Accordion
                  expanded={collapsed?.[val] ?? false}
                  onChange={() => handleClick(val)}
                  key={index}
                  TransitionProps={{ unmountOnExit: true }}
                  className={classes.accordion}
                  data-testid={`accordion-${index}`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    className={classes.accordionSummaryContent}
                  >
                    <Typography>
                      {val
                        ? moment(val).format('ddd, MMM DD, YYYY')
                        : DATE_NOT_SET}
                    </Typography>
                    <Typography className={classes.marginAccordion}>
                      {`${totalWorkOrders} Work Order${
                        totalWorkOrders === 1 ? '' : 's'
                      } (${totalLaborHours} ${
                        isDCUser ? 'Assigned' : 'Scheduled'
                      })`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    {renderCards(filteredData[val])}
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </List>
        )}

        {shiftKeys && isDCUser && <ScheduleShifts />}
      </>
    )
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MatAppBar arrowBack text={SCHEDULE_OR_ASSIGNMENT(isDCUser)} />
        {!error && assignments?.length === 0 && !loading && (
          <Alert severity="warning" data-testid={'no-data-text'}>
            {NO_SCHEDULES(isDCUser)}
          </Alert>
        )}
        {error && !loading && (
          <Alert severity="error">
            Unable to connect to {SCHEDULE_OR_ASSIGNMENT(isDCUser)} Service
          </Alert>
        )}
        {assignments?.length > 0 && !error && (
          <>
            <Alert
              severity="info"
              variant="outlined"
              className={classes.alertInfo}
              data-testid="info-alert"
              classes={{ action: classes.alertAction }}
              action={
                <IconButton
                  onClick={toggleSorting}
                  color="primary"
                  variant="outlined"
                >
                  <SortIcon data-testid="sort-button" />
                </IconButton>
              }
            >
              <div className={classes.headerLeft}>
                <div>
                  {`${Object.values(filteredData).flat().length} ${
                    isDCUser ? 'Assigned' : 'Scheduled'
                  }`}
                  &nbsp;
                </div>
                {getDateRange(
                  headerDates?.current?.lastDate,
                  headerDates?.current?.startDate,
                  isReversed,
                )}
              </div>
            </Alert>
            {!isDCUser && (
              <div className={classes.filterBorder}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  rowSpacing={2}
                  flexWrap="nowrap"
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item className={classes.dateFilterDiv}>
                    <DesktopDatePicker
                      label="From Date"
                      inputFormat="MM/DD/yyyy"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      OpenPickerButtonProps={{ sx: { padding: 0.5 } }}
                    />
                    <DesktopDatePicker
                      label="To Date"
                      inputFormat="MM/DD/yyyy"
                      value={toDate}
                      onChange={handleToDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      OpenPickerButtonProps={{ sx: { padding: 0.5 } }}
                    />
                  </Grid>
                  {(fromDate || toDate) && (
                    <Grid item>
                      <IconButton
                        aria-label="Clear filters"
                        size="small"
                        onClick={() => {
                          dispatch(setFromDate(null))
                          dispatch(setToDate(null))
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </>
        )}
        {renderCollapsedWOByDate()}
      </LocalizationProvider>
    </>
  )
}

export default Schedules
