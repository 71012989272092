/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import { fetchData, postData, putData } from '../../service/HttpService'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  Button,
  Grid,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { getURLSearchParams } from '../../windowManager'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { setWorkOrder } from '../WorkOrderDetails/store/actionCreator'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_ERROR_MESSAGE,
  LABOR_ENTRIES_KEY,
  TIME_TRACKING_MIN_TIME,
  api,
} from '../../globalConstants'
import { isHoursInvalid, isMinutesInvalid } from '../../utils'
import AddTime from '../AddTime/AddTime'
import useHashChange from '../../utils/hooks/useHashChange'
import { setTimeEntries } from '../TimeTracking/store/actionCreator'
import { formattedTime } from '../../utils'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    timeWarning: {
      margin: theme.spacing(0, 3, 1, 3),
    },
    dialogActions: {
      padding: theme.spacing(0, 3, 2, 0),
    },
  }),
)

const AddTimeModal = ({
  timeTracking = false,
  isEditing = false,
  timeToEdit,
  dateToEdit,
  setIsEditing,
  laborId,
  laborType,
  dayLaborTime,
  todayLaborTime,
  filteredData,
  woNumToEdit,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId?.toUpperCase()
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [openAgreeToOver8HrsModal, setOpenAgreeToOver8HrsModal] =
    useState(false)
  const [isExceeding8Hrs, setIsExceeding8Hrs] = useState(false)
  useHashChange('add_time_open', setOpen)
  const [error, setError] = useState(false)
  const timeInHoursAndMinutes = formattedTime(timeToEdit)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [userLoggedTime, setUserLoggedTime] = useState('')
  const [isUserTimeLimitReached, setIsUserTimeLimitReached] = useState(false)
  const [laborTypeList, setLaborTypeList] = useState([])
  const [selectedLaborType, setSelectedLaborType] = useState('')
  const [dateValue, setDateValue] = useState(moment())
  const [isOneMin, setIsOneMin] = useState(false)
  const [calculatedDayLaborTime, setCalculatedDayLaborTime] = useState(0)
  const { storeId = null, isDCUser } = useSelector((state) => ({
    ...state.storeInfoReducer,
    ...state.userInfoReducer,
  }))
  const timeToAdd = moment.duration(`${hours}:${minutes}`).asHours()
  let proposedTotalHours =
    timeTracking && !isEditing
      ? timeToAdd + todayLaborTime
      : dayLaborTime + (timeToAdd - timeToEdit)

  useEffect(() => {
    try {
      getUserLoggedTime(storeId)
      getLaborTypes()
    } catch (error) {
      setError(true)
    }
  }, [])

  useEffect(() => {
    setIsOneMin(!hours && minutes === '1' ? false : true)
  }, [hours, minutes])

  useEffect(() => {
    setDateValue(isEditing ? moment(dateToEdit) : moment())
    setHours(isEditing ? timeInHoursAndMinutes?.hr : 0)
    setMinutes(isEditing ? timeInHoursAndMinutes?.min : 0)
  }, [isEditing])

  useEffect(() => {
    if (proposedTotalHours > 8) {
      setIsExceeding8Hrs(true)
      setIsUserTimeLimitReached(true)
    }
  })

  const checkIfOver8Hours = () => {
    const selectedDate = moment(dateValue).format()
    const today = moment().format()
    if (
      (selectedDate === today && proposedTotalHours > 8) ||
      (selectedDate !== today && calculatedDayLaborTime + timeToAdd > 8)
    ) {
      setOpenAgreeToOver8HrsModal(true)
      setIsExceeding8Hrs(false)
      setOpen(false)
    } else {
      saveTime()
    }
  }

  const calculateDayLaborTimeFloat = (date) => {
    setCalculatedDayLaborTime(0.0)
    const formattedDate = moment(date).format(DEFAULT_DATE_FORMAT)
    for (const [arrayName] of Object.entries(filteredData)) {
      let sectionTimeTotal = 0.0
      if (arrayName.includes(formattedDate)) {
        filteredData[arrayName].forEach((laborEntry) => {
          if (laborEntry?.time) {
            sectionTimeTotal += parseFloat(laborEntry.time)
          }
        })
        setCalculatedDayLaborTime(sectionTimeTotal)
      }
    }
  }

  const saveTime = async () => {
    const requestBody = {
      'regular-hrs': moment.duration(`${hours}:${minutes}`).asHours(),
      'trans-type': timeTracking
        ? laborTypeList.find((labor) => labor.description === selectedLaborType)
            ?.value
        : 'WORK',
      wonum: woNum,
      siteid: storeId,
      'labor-code': userId,
      'start-date': moment().format(),
      ...(isUserTimeLimitReached && { 'api-update': 8 }),
      'exeeding-8hrs': isExceeding8Hrs,
    }

    const editRequestBody = {
      'labortrans-id': laborId,
      'labor-code': userId,
      'regular-hrs': moment.duration(`${hours}:${minutes}`).asHours(),
      'api-update': 8,
      siteid: storeId,
      wonum: woNumToEdit ? woNumToEdit : null,
      'trans-type': laborType,
      'start-date': `${moment(dateToEdit).format('YYYY-MM-DD')}${moment().format('THH:mm:ssZ')}`,
      'exeeding-8hrs': isExceeding8Hrs,
    }

    try {
      dispatch(setShouldRender(true, 'Saving Time..'))
      isEditing
        ? await putData(
            '/maximo/labortransaction/update',
            JSON.stringify(editRequestBody),
          )
        : await postData(
            '/maximo/labortransaction',
            JSON.stringify(requestBody),
          )

      if (timeTracking) {
        const laborResponse = await fetchData(
          api.laborEntries(storeId, userId?.toUpperCase()),
        )
        const myTimeEntries = laborResponse?.[LABOR_ENTRIES_KEY] ?? []
        dispatch(setTimeEntries(myTimeEntries))
        setSelectedLaborType('')
        setDateValue(moment())
      } else {
        const woResponse = await fetchData(`/mam/workorder/wonum/${woNum}`)
        dispatch(setWorkOrder(woResponse.woDetails[0]))
      }

      await getUserLoggedTime(storeId)
      setIsUserTimeLimitReached(false)

      closeModal()
      handleCloseExceeds8HoursModal()

      setHours(0)
      setMinutes(0)
      setError(false)
      isEditing && setIsEditing(false)
    } catch (error) {
      setError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const getLaborTypes = async () => {
    const laborTypeResp = await fetchData('/mam/timetypelist')
    const orgId = isDCUser ? 'TGTDC' : 'TGTSTORE'
    const filteredLaborType = laborTypeResp.timetypedata.filter(
      (obj) => obj.orgid === orgId && obj.value !== 'WORK',
    )
    setLaborTypeList(filteredLaborType)
  }

  const getUserLoggedTime = async (locationId) => {
    const userTimeResp = await fetchData(
      api.userLaborEntries(locationId, userId),
    )
    const formattedUserTime = userTimeResp.totalTime
      .replace('(Total Today: ', '')
      .slice(0, -1)
      .replace(',', '')
    setUserLoggedTime(formattedUserTime)
  }

  const closeModal = () => {
    const path = timeTracking
      ? '/time-tracking'
      : `${location.pathname}#?wonum=${woNum}&showBack=true`
    navigate(path)
    setError(false)
  }

  const handleLaborType = async (e) => {
    setSelectedLaborType(e.target.value)
  }

  const handleDateChange = (newValue) => {
    setDateValue(newValue)
    calculateDayLaborTimeFloat(newValue)
  }

  const handleCancel = () => {
    if (isEditing) {
      try {
        window.history.back()
      } catch (error) {
        console.warn('error')
      } finally {
        setIsEditing(false)
      }
    } else {
      window.history.back()
    }
    setError(false)
  }

  const handleCloseExceeds8HoursModal = () => {
    setOpenAgreeToOver8HrsModal(false)
    setIsExceeding8Hrs(false)
    setOpen(true)
  }

  return (
    <>
      <Dialog open={openAgreeToOver8HrsModal}>
        <DialogTitle>8 Hours Exceeded</DialogTitle>
        <DialogContent>
          <Typography>
            You have exceeded 8 hours for this date. Do you want to continue?
          </Typography>
          <Typography>If No, this record will not be saved.</Typography>
          <DialogActions className={classes.dialogActions}>
            <Button variant="outlined" onClick={handleCloseExceeds8HoursModal}>
              No
            </Button>
            <Button variant="contained" onClick={saveTime}>
              Yes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog open={open && !openAgreeToOver8HrsModal} onClose={handleCancel}>
        <DialogTitle>Add Time</DialogTitle>
        {error && <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Total Today:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid="total-hours">
                {userLoggedTime ?? '0 Hrs 0 Mins'}
              </Typography>
            </Grid>
          </Grid>
          <AddTime
            hours={hours}
            minutes={minutes}
            onHoursChange={setHours}
            onMinutesChange={setMinutes}
          />
          <>
            <Grid container spacing={2} rowSpacing={1}>
              {timeTracking && !isEditing && (
                <Grid item xs={12}>
                  <FormControl fullWidth size="small" data-testid="labor-form">
                    <InputLabel>Labor Type</InputLabel>
                    <Select
                      value={error ? '' : selectedLaborType}
                      inputProps={{ 'data-testid': 'labor-type' }}
                      label="Labor Type"
                      aria-label="Labor Type"
                      onChange={(e) => handleLaborType(e)}
                    >
                      {laborTypeList.length > 0 &&
                        !error &&
                        laborTypeList.map((laborType, index) => {
                          return (
                            <MenuItem key={index} value={laborType.description}>
                              {laborType.description}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {timeTracking && (
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      data-testid="date-picker"
                      inputFormat="MM/DD/YYYY"
                      value={dateValue}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            (dateValue &&
                              dateValue.isBefore(
                                moment().subtract(11, 'days'),
                              ) &&
                              'Cannot be more than 10 days in the past.') ||
                            (dateValue &&
                              dateValue.isAfter(moment().add(11, 'days')) &&
                              'Cannot be more than 10 days in the future.')
                          }
                        />
                      )}
                      minDate={moment().subtract(10, 'days')}
                      maxDate={moment().add(10, 'days')}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
            </Grid>
          </>
        </DialogContent>
        {!isOneMin && (
          <Alert severity="warning" className={classes.timeWarning}>
            {TIME_TRACKING_MIN_TIME}
          </Alert>
        )}
        <DialogActions className={classes.dialogActions}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            data-testid="save-time-button"
            disabled={
              (!hours && !minutes) ||
              !isOneMin ||
              isHoursInvalid(hours) ||
              isMinutesInvalid(minutes) ||
              (timeTracking && selectedLaborType === '' && !isEditing) ||
              (timeTracking && dateValue === null) ||
              (timeTracking && !dateValue.isValid()) ||
              (timeTracking &&
                dateValue.isBefore(moment().subtract(11, 'days'))) ||
              (timeTracking && dateValue.isAfter(moment().add(11, 'days'))) ||
              (timeInHoursAndMinutes?.hr === hours &&
                timeInHoursAndMinutes?.min === minutes &&
                moment(dateToEdit).isSame(dateValue))
            }
            variant="contained"
            onClick={checkIfOver8Hours}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddTimeModal
